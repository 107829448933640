import "./App.css";
import Logo from "./images/Q-Logo-bg.png";
import CookieConsent from "react-cookie-consent";

function App() {
  const ButtonData = [
    {
      title: "Show 1",
      linkUrl: "https://show1.q-tech.dev",
    },
    {
      title: "Show 2",
      linkUrl: "https://show2.q-tech.dev",
    },
    {
      title: "Show 3",
      linkUrl: "https://show3.q-tech.dev",
    },
    {
      title: "Show 4",
      linkUrl: "https://show4.q-tech.dev",
    },
    {
      title: "Show 5",
      linkUrl: "https://show5.q-tech.dev",
    },
    {
      title: "Show 6",
      linkUrl: "https://show6.q-tech.dev",
    },
  ];

  return (
    <div className="App">
      <div className="bild">
        <img src={Logo} alt="Q-Tech.dev Logo" />
        <h1>Qualified Technologies Development</h1>
        <div className="hero__btns">
          <a href="https://q-tech.dev" rel="noreferrer" target="_blank">
            <button className="primary__btn">Q-Tech Dev</button>
          </a>
        </div>
        <div className="hero__btns">
          <a href="https://q-tech.dev/kontakt" rel="noreferrer" target="_blank">
            <button className="primary__btn">Kontakt</button>
          </a>
        </div>
      </div>

      {/*       <div className="buttons">
        <h2>Show Webseiten</h2>
        {ButtonData.map((item, index) => (
          <div className="hero__btns" key={index}>
            <a href={item.linkUrl} rel="noreferrer" target="_blank">
              <button className="primary__btn">{item.title}</button>
            </a>
          </div>
        ))}
      </div> */}

      <div className="text">
        <p>
          Diese Domain ist von Qualified Technologies Development reserviert,
          <br />
          bei interessere der Domain oder einer Webseite,
          <br /> über denn Kontakt button kontaktieren.
        </p>
      </div>

      <div className="footer">
        <p>
          &copy; {new Date().getFullYear()} by{" "}
          <a href="https://q-tech.dev" rel="noreferrer" target="_blank">
            Qualified Technologies Development
          </a>
        </p>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Bestätigen"
        cookieName="myAwesomeCookieName2"
        style={{ background: "", color: "var(--small-text-color)" }}
        buttonStyle={{ color: "var(--btn-primary-bg)", background: "var(--color-primary)", border: "1px solid var(--btn-primary-bg)", fontSize: "13px" }}
        expires={15}
        >
        Diese Website verwendet Cookies und Analytics, um die Benutzererfahrung zu verbessern.{" "}
    </CookieConsent>
    </div>
  );
}

export default App;
